import React from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import soundfile from '../../../assets/sound/beep-06.wav';

function QR_BarcodeScanner() {
    const sound = new Audio(soundfile);
    const [data, setData] = React.useState("Not Found");
    const [stopStream, setStopStream] = React.useState(false);

    return (
        <>
        <h2>QR Barcode Scanner</h2>
        <div className="cameraOutput">
            <BarcodeScannerComponent
                width={400}
                height={400}
                stopStream={stopStream}
                onUpdate={(err, result) => {
                    if (result) {
                        sound.play();
                        setData(result.text);
                        setStopStream(true);
                    }
                    else {
                        setData("Not Found");
                    }
                }}
            />
        </div>
        <p>{data}</p>
    </>
);
}

export default QR_BarcodeScanner;
