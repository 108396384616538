import React, { useState } from 'react'
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import soundfile from '../../../assets/sound/beep-06.wav'

function ReactBarcodeReader(){

const sound = new Audio(soundfile);
const [data, setData] = React.useState("Not Found");

  const processUpdate = (newData) => {
    console.log({ newData });
    if (newData && newData.text) {
      sound.play();
      setData(newData.text);
    }
  };

  return (
    <React.Fragment>
      <h2>Webcam Barcode Scanner</h2>
      <div className="cameraOutput">
          <BarcodeScannerComponent
            width={400}
            height={400}
            onUpdate={(err, result) => {
            if (err) {
                console.error(err);
            } else {
                processUpdate(result);
            }
        }}
      />
      </div>
      <p>{data}</p>
    </React.Fragment>
  );
}

export default ReactBarcodeReader;