import React, { useEffect, useState } from 'react';
import ScanditBarcodeScanner from "scandit-sdk-react";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { TextareaAutosize, Paper, Button } from '@material-ui/core';
import styled from 'styled-components';

import {
    Barcode,
    BarcodePicker,
    Camera,
    CameraAccess,
    CameraSettings,
    ScanSettings,
    SingleImageModeSettings,
  } from "scandit-sdk";

  const ResetOutlinedButton = styled(Button)`
  //   border-radius: 8px;
  
    box-shadow: none;
    text-transform: none;
    font-size: 12px;
    align-self: center;
      //height: 47px;
    background: #fff;
    color: blue;
    &:hover {
      background: #fff;
    }
  
    .MuiButton-label {
      justify-content: flex-start;
    }
  
    &.MuiButtonBase-root.MuiButton-label.MuiButton-startIcon{
      display: inherit;
      margin-left: -4px;
      margin-right: 0px;
    }
  
    &.MuiButtonBase-root.Mui-disabled {
      color: darkgrey;
      border: 2px solid darkgrey !important;
    }
  `;
  
  const TextArea = styled(TextareaAutosize)`
  font-size: 13px;
  width: ${(props) => (props.one ? '55%' : '20%')};
  margin-right: 11px;
  margin-top: 3px;
  margin-left: 6px;
  display: inline-block;
  `;

function ScanBarCodeScandit() {

    const [barCode, setBarCode] = useState(null);

    const [barCodeFormat, setBarCodeFormat] = useState(null);
    const [showCam, setShowCam] = useState(true);
    const [barCodes, setBarCodes] = useState([]);

    const onReadyFunction = () => {
        this.setState({ scannerReady: true })
    }

    const resetAll = () => {
        setShowCam(true);
        setBarCode(null);
        setBarCodeFormat(null);
        setBarCodes([]);
    }

    const onDetect = (x) => {
        console.log("inside onDetect");
        console.log(x);
        setShowCam(false);
        setBarCodes(x.barcodes);
        setBarCode(x.barcodes[0].symbology); 
        setBarCodeFormat(x.barcodes[0].data);
    };
    
    //const licenseKey = "ASqyJi0oDs0nJ4cPGkBhSkQEBHR0AbRah1tZ54NpvDknSR1wKWArGNJ08zZYZrm1pV9jzYQX5CnGWxkGRmTNz89PLjTzTwQ0OmKjznFsWylwLBBMYgtiMOQVhP9JDYn7vUr4vAviHAeteZjHnEQrru+jap825+u7AAe5uoofArU1v3qWDq6VCMyPgHlhyUsXh9/o8tRfuxl1c3RjcOlQoaxXqzzN3ePFoaCqFM8PHjpxDlSoP6lVMIvJ61TAJIig1XSPanCMO+1Z2cHE91y2ih2hZybfRtizznRiLzTiMHv9pCw5pjWJvwJnH3CxvdmZMJddGL0kksvS19R5n5zhkQO9Tl8HdAeAzcfR4vluEgLTeENy+YG1BbOB6XC9+DSk90CSrEIe8GD9hROTd6is8B6aYlJyGOHShkRTwznm4gsDTEogJ50FGrLs2HQF3vn66n1z14dZ4VhJqcTllCLKnSZ3/NTYdGiB/tu+Jh/V1IeyisTdu/NcUa6EJ+1OSzPvwhKqidi/aO5mbJad7rTulXLTaGAuXUzIayYh/Bf4P9LkBLuZCwqZw4RYr8ub85EeP+oBX9ufL0UKs4+KHh5RcZ0L4wSO8SXcF5AWMGiijAcMzo0bltpYM9bbu0GYy0s4Ffu/4MOxRdlEHEIaa8NkTK5qSza17aUAoDC70iNFEivK41UtQ/sIjB97ia771BSTYvi7EIeM7jIQ+NkaDsZfFDalsB0scfq4BxuPlChlYH0UxT0lm74Jn31QkUfdsSYMD6mxBSP/lk2bRFVG6Z5nDv38wxTp/qjCZYmmtpnWjw==";
    const licenseKey = "AcIy5xsmOOVwFfAGLTcGTugBWCFSANJ7HEalalt47ajrbTfxJTiJSMNujURpdo3fp2YqxqR8zZ55GBnKuSzByUhg7mSsRU2q2zaYt2BhWci6V5MNyW50EMx/gb1DSUuy61+Q/UdPnl0BSTjowCIs6TlLck+uBA4keVCT4hg+g+ABJfEfqj36HscI5hKCCA3hq1yhD7ersY+bKa1mIxReUrRmSEvaYTX6At1ECyQnRHhOfLMMAtK9sdan6NaRZFf2/QbFYCf9Hjl4q8Ssop/6+Y5OSkR88pDf7P01IouXZaqjLJGzJdesGoeBLwOJiG1dAX2j2VtZNmC3kTb1/zwCw+wkiqMIjJI1hRYRu8l+wnSOWgzRrDdijmY8o2+3cY7v+ILB5p474kdxeqPAWzqXyjpKT6kZJumk/La27GHBQIDKwRKe70fXtTwsNOA8aASlT9Nv1LCplaAuiEnrar93c2gNEmf+xH94/5iq2yxD9W34qbljIABr0efL/u0bjxH8vrN2EtgV4Ghtd/nQpd/5LMxjqi4Tg/8lDG2ThFavBZgjYg8eUutR0ZD94s8Kz3rY+4/E2iINyCdolxISZbaVQRwByu6CHArch/g9Q1SdY3/9TqchdipE7skgJA1P//0HOVhqdvwj4aX1FWe9fUy3zoHxkm1BUj5nhxTAndD3Bq6SX9BxSwbV6wuERGLufg6Y44J7uvURm4F9CSXQg2BsHbYIk5rlVGDZIiv54QkygzStQp/aWnDBhjLHW6qtQbY42Sj2f6jsSmjaKPARVoZy9CXKpMK4SjUwU+CZPk6tRpEtdXt5TEO3ZT929PbPUx9Fszs0XBHzFPPDw7w+mhQJGfh3OCjjR2xxo74rC2ij";

    return (
        <div>
            <h2>Scandit Scanner</h2>
            <Paper variant="outlined" style={{ marginTop: 31, display: 'flex',justifyContent: 'center' }}>
                {showCam && <ScanditBarcodeScanner
                // Library licensing & configuration options (see https://docs.scandit.com/stable/web/globals.html#configure)
                licenseKey={licenseKey}
                engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build" // could also be a local folder, e.g. "build"
                // Picker events
                onReady={(x) => {console.log(x)}}
                onScan={(x) => {onDetect(x)}}
                onScanError={console.log}
                // Picker options
                scanSettings={
                    new ScanSettings({
                    enabledSymbologies: [
                        "qr",
                        "ean8",
                        "ean13",
                        "upca",
                        "upce",
                        "code128",
                        "code39",
                        "code93",
                        "itf"
                    ],
                    codeDuplicateFilter: -1
                    })
                }/>
                }
            </Paper>
            <div style ={{display: 'flex', justifyContent: 'space-between', paddingBottom: 5,paddingTop: 5 }}>
                <TextArea
                    one='true'
                    maxRows={1}
                    value={ barCode ?? 'No data scanned' }
                />
                <TextArea
                    two='true'
                    maxRows={1}
                    value={ barCodeFormat ?? 'No code' }
                />

                    <ResetOutlinedButton
                    startIcon={<ExitToAppIcon />}
                    onClick={resetAll}
                    >
                    Reset
                    </ResetOutlinedButton>
                </div>
                <div>
                <ul style={{ width:'40%' }}>
                    {/*console.log("barcodes : "+barCodes)*/}
                    <div>Top {barCodes.length} reads :</div>
                    {barCodes.map((val) => (
                        <li className='rowstyle' key={val.data}>{val.data} -- {val.symbology}</li>
                    ))}
                </ul>
            </div>
        </div>)
}

export default ScanBarCodeScandit;