import React, { useState } from "react";
import ScanBarCode from "./pages/BarcodeScanner/QuaggaJs/ScanBarCode";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import QR_BarcodeScanner from "./pages/BarcodeScanner/QR_BarcodeScanner";
import ReactBarcodeReader from "./pages/BarcodeScanner/ReactBarcodeReader";
import ScanBarCodeScandit from "./pages/BarcodeScanner/Scandit_BarcodeScanner";
import ZxingBarcodeScanner from "./pages/BarcodeScanner/zxing";
import ZxingJSBarcodeScanner from "./pages/BarcodeScanner/zxingVideo-js";
import Html5QrcodePlugin from "./pages/BarcodeScanner/Html5QRCode/Html5QrCodePlugin";
import Scanner from "./pages/BarcodeScanner/zxing-img";
import "./App.css";

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BarcodeBtn = styled(Button)`
  margin-right: 13px !important;
  color: ${(props) => props.color}!important;
`;

const onNewScanResult = (res) => {
  console.log(res);
  alert(res);
}

function App() {
  const [showQR, setShowQR] = useState("");
  return (
    <div className="App">
      <BtnContainer>
        <BarcodeBtn
          color="green"
          variant="outlined"
          onClick={() => setShowQR("0")}
        >
          QR Scanner
        </BarcodeBtn>
        <BarcodeBtn
          variant="outlined"
          color="red"
          onClick={() => setShowQR("1")}
        >
          Quagga
        </BarcodeBtn>
        <BarcodeBtn
          variant="outlined"
          color="blue"
          onClick={() => setShowQR("2")}
        >
          React Barcode Reader
        </BarcodeBtn>
        <BarcodeBtn
          variant="outlined"
          color="blue"
          onClick={() => setShowQR("3")}
        >
          Scandit Barcode
        </BarcodeBtn>
        <BarcodeBtn
          variant="outlined"
          color="blue"
          onClick={() => setShowQR("4")}
        >
          Zxing Barcode
        </BarcodeBtn>

      </BtnContainer>
      <div>
        {showQR === "0" && <QR_BarcodeScanner />}
        {showQR === "1" && <ScanBarCode />}
        {showQR === "2" && <ReactBarcodeReader />}
        {showQR === "3" && <ScanBarCodeScandit />}
        {showQR === "4" && 
          <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
          />
        }
      </div>
    </div>
  );
}

export default App;
