import React, { useState } from 'react';
import { useZxing } from "react-zxing";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { TextareaAutosize, Paper, Button } from '@material-ui/core';
import styled from 'styled-components';

const ResetOutlinedButton = styled(Button)`
  //   border-radius: 8px;
  
    box-shadow: none;
    text-transform: none;
    font-size: 12px;
    align-self: center;
      //height: 47px;
    background: #fff;
    color: blue;
    &:hover {
      background: #fff;
    }
  
    .MuiButton-label {
      justify-content: flex-start;
    }
  
    &.MuiButtonBase-root.MuiButton-label.MuiButton-startIcon{
      display: inherit;
      margin-left: -4px;
      margin-right: 0px;
    }
  
    &.MuiButtonBase-root.Mui-disabled {
      color: darkgrey;
      border: 2px solid darkgrey !important;
    }
  `;
  
  const TextArea = styled(TextareaAutosize)`
  font-size: 13px;
  width: ${(props) => (props.one ? '55%' : '20%')};
  margin-right: 11px;
  margin-top: 3px;
  margin-left: 6px;
  display: inline-block;
  `;

function ZxingBarcodeScanner() {
    const [result, setResult] = useState("");
    const [barCode, setBarCode] = useState(null);
    const [barCodeFormat, setBarCodeFormat] = useState(null);
    const [showCam, setShowCam] = useState(true);
    const [barCodes, setBarCodes] = useState([]);

    const resetAll = () => {
        setShowCam(true);
        setBarCode(null);
        setBarCodeFormat(null);
        setBarCodes([]);
    }

    const { ref } = useZxing({
      onResult(result) {
        console.log("result : "+result);
        setResult(result.getText());
      },
    });
  
    return (
      <div>
        <h2>Zxing Scanner</h2>
            <video ref={ref} />
            <div style ={{display: 'flex', justifyContent: 'space-between', paddingBottom: 5,paddingTop: 5 }}>
                <TextArea
                    one='true'
                    maxRows={1}
                    value={ barCode ?? 'No data scanned' }
                />
                <TextArea
                    two='true'
                    maxRows={1}
                    value={ barCodeFormat ?? 'No code' }
                />

                    <ResetOutlinedButton
                    startIcon={<ExitToAppIcon />}
                    onClick={resetAll}
                    >
                    Reset
                    </ResetOutlinedButton>
            </div>
            <div>
                <ul style={{ width:'40%' }}>
                    {/*console.log("barcodes : "+barCodes)*/}
                    <div>Top {barCodes.length} reads :</div>
                    {barCodes.map((val) => (
                        <li className='rowstyle' key={val.data}>{val.data} -- {val.symbology}</li>
                    ))}
                </ul>
            </div>

      </div>
    );
  };

  export default ZxingBarcodeScanner;