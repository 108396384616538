import React, { Fragment, useEffect } from "react";
import Quagga from "quagga";
import styled from "styled-components";
import "./Scanner.css";
import soundfile from "../../../../assets/sound/beep-06.wav";

const Scanarea = styled.div`
  width: 60%;
  height: 50vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Scanner({ onBarcodeDetect, onBarcodeDetectArr }) {
  let lastResult = [];
  const sound = new Audio(soundfile);

  const orderByOccurence = (arr) => {
    var counts = {};
    arr.forEach((val) => {
      if (!counts[val]) {
        counts[val] = 0;
      }
      counts[val]++;
    });
    console.log("before sort", counts);
    return Object.keys(counts).sort((curKey, nextKey) => {
      return counts[curKey] - counts[nextKey];
    });
  };

  const findOcc = (arr, key) => {
    let arr2 = [];

    arr.forEach((x) => {
      // Checking if there is any object in arr2
      // which contains the key value
      if (
        arr2.some((val) => {
          return val[key] == x[key];
        })
      ) {
        // If yes! then increase the occurrence by 1
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["occurrence"]++;
          }
        });
      } else {
        // If not! Then create a new object initialize
        // it with the present iteration key's value and
        // set the occurrence to 1
        let a = {};
        a[key] = x[key];
        a["format"] = x["format"];
        a["occurrence"] = 1;
        arr2.push(a);
      }
    });

    return arr2;
  };

  const onDetect = (res) => {
    //var audio = new Audio('./beep.mp3');
    lastResult.push({
      code: res.codeResult.code,
      format: res.codeResult.format,
    });
    //setResetCam(false);
    if (lastResult.length > 20) {
      console.log("lastResult ::", lastResult);
      //let bcodeArr = orderByOccurence(lastResult);
      let bcodeArr = findOcc(lastResult, "code").sort((cur, next) => {
        return next.occurrence - cur.occurrence;
      });
      console.log("after sort", bcodeArr);
      onBarcodeDetectArr(bcodeArr.slice(0, 4));
      let bcode = bcodeArr[0];
      lastResult = [];
      //audio.play();
      sound.play();
      Quagga.stop();
      Quagga.offProcessed();
      onBarcodeDetect(bcode.code, bcode.format);
    }
  };

  useEffect(() => {
    setTimeout(function () {
      var track = Quagga.CameraAccess.getActiveTrack();
      var capabilities = {};
      if (typeof track.getCapabilities === "function") {
        try {
          capabilities = track.getCapabilities();
          track.applyConstraints({
            advanced: [{ zoom: capabilities.zoom.max }],
          });
        } catch (e) {
          console.log(e);
        }
      }
    }, 500);

    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          constraints: {
            width: "790",
            height: "490",
            facingMode: "environment",
          },
          area: {
            top: "35%",
            right: "5%",
            left: "5%",
            bottom: "35%",
          },
          singleChannel: false,
          numberOfWorkers: navigator.hardwareConcurrency,
          target: document.querySelector("#barcodeScan"),
        },
        locator: {
          //halfSample: true,
          patchSize: "medium", // x-small, small, medium, large, x-large
          debug: {
            showCanvas: true,
            showPatches: true,
            showFoundPatches: true,
            showSkeleton: true,
            showLabels: true,
            showPatchLabels: false,
            showRemainingPatchLabels: false,
            boxFromPatches: {
              showTransformed: true,
              showTransformedBox: true,
              showBB: true,
            },
          },
        },
        locate: false,
        decoder: {
          readers: [
            "code_128_reader",
            "ean_reader",
            "ean_8_reader",
            "code_39_reader",
            "code_39_vin_reader",
          ],
          debug: {
            drawBoundingBox: true,
            showFrequency: true,
            drawScanline: true,
            showPattern: true,
          },
        },
      },
      function (err) {
        if (err) {
          return;
        }
        Quagga.start();
      }
    );

    Quagga.onProcessed(function (result) {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute("width")),
            parseInt(drawingCanvas.getAttribute("height"))
          );
          result.boxes
            .filter(function (box) {
              return box !== result.box;
            })
            .forEach(function (box) {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: "green",
                lineWidth: 2,
              });
            });
        }

        if (result.box) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute("width")),
            parseInt(drawingCanvas.getAttribute("height"))
          );
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: "#00F",
            lineWidth: 2,
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: "x", y: "y" },
            drawingCtx,
            { color: "red", lineWidth: 3 }
          );
        }
      }
    });

    Quagga.onDetected(onDetect);
  }, []);

  return (
    <Fragment>
      <Scanarea id="barcodeScan"></Scanarea>
    </Fragment>
  );
}

export default Scanner;
