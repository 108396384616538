import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType } from '@zxing/library';
import React, { useEffect, useState } from 'react';
import { useZxing } from "react-zxing";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { TextareaAutosize, Paper, Button } from '@material-ui/core';
import styled from 'styled-components';

const ResetOutlinedButton = styled(Button)`
  //   border-radius: 8px;
  
    box-shadow: none;
    text-transform: none;
    font-size: 12px;
    align-self: center;
      //height: 47px;
    background: #fff;
    color: blue;
    &:hover {
      background: #fff;
    }
  
    .MuiButton-label {
      justify-content: flex-start;
    }
  
    &.MuiButtonBase-root.MuiButton-label.MuiButton-startIcon{
      display: inherit;
      margin-left: -4px;
      margin-right: 0px;
    }
  
    &.MuiButtonBase-root.Mui-disabled {
      color: darkgrey;
      border: 2px solid darkgrey !important;
    }
  `;
  
  const TextArea = styled(TextareaAutosize)`
  font-size: 13px;
  width: ${(props) => (props.one ? '55%' : '20%')};
  margin-right: 11px;
  margin-top: 3px;
  margin-left: 6px;
  display: inline-block;
  `;

function ZxingJSBarcodeScanner() {

    /*const [result, setResult] = useState("");
    const [barCode, setBarCode] = useState(null);
    const [barCodeFormat, setBarCodeFormat] = useState(null);
    const [showCam, setShowCam] = useState(true);
    const [barCodes, setBarCodes] = useState([]);

    const resetAll = () => {
        setShowCam(true);
        codeReader.reset();
        console.log('Reset.')
        setBarCode(null);
        setBarCodeFormat(null);
        setBarCodes([]);
    }*/
    
    const [ videoInputDevices, setVideoInputDevices ] = useState ( [] );
    const [ selectedVideoDevice, selectVideoDevice ] = useState ( '' );
    const hints = new Map ();
    const formats = [ BarcodeFormat.CODE_128, BarcodeFormat.CODE_39, BarcodeFormat.EAN_8, 
        BarcodeFormat.EAN_13, BarcodeFormat.CODE_93 ];
    hints.set ( DecodeHintType.POSSIBLE_FORMATS, formats );
    const reader = new BrowserMultiFormatReader ( hints );

    console.log('ZXing code reader initialized');

    useEffect ( () =>
    {
        ( async () =>
        {
            const videoInputDeviceList = await reader.listVideoInputDevices ();
            setVideoInputDevices ( videoInputDeviceList );
            if ( videoInputDeviceList.length > 0 )
            {
                selectVideoDevice ( videoInputDeviceList[ 0 ].deviceId );
            }
        } ) ();
    }, [] );

    useEffect ( () =>
    {
        if ( selectedVideoDevice )
        {
            reader.reset ();
            reader.decodeFromVideoDevice ( selectedVideoDevice, 'videoElement', ( res ) =>
            {
                if ( res )
                {
                    console.log ( 'result is', res );
                    alert ( res.getText () );
                }
            } ).then ( res => console.log ( 'result', res ) ).catch ( err => console.log ( 'error', err ) );
        }
    }, [ selectedVideoDevice ] );

    

    /*useEffect(() => {
            const hints = new Map();
            const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.CODE_128, BarcodeFormat.ITF];
            hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
            const codeReader = new BrowserMultiFormatReader(hints, {
                delayBetweenScanSuccess: 2000,
                delayBetweenScanAttempts: 600,
            });
            var controls = null;
            controls = codeReader
                .decodeFromVideoDevice(undefined, video.current, function (result, error) {
                    if (typeof result !== "undefined") {
                        callback(result); // redirect on different page and not render this
                    }
                })
            console.log(controls);

            return function cleanup() {
                console.log('clean');
                //controls.stop();
            };
    });*/


    return (
        <div>
            <h2>Zxing JS Scanner</h2>
            {/*<Paper variant="outlined" style={{ marginTop: 31, display: 'flex',justifyContent: 'center' }}>
                {showCam && <video ref={video} width="300" height="200" style="border: 1px solid gray"/>}
            </Paper>*/}

            <div
                onChange={ ( event ) =>
                {
                    const deviceId = event.target.value;
                    selectVideoDevice ( deviceId );
                } }>
                <select>
                    {
                        videoInputDevices.map ( ( inputDevice, index ) =>
                        {
                            return <option value={ inputDevice.deviceId }
                                        key={ index }>
                                { inputDevice.label || inputDevice.deviceId }
                            </option>
                        } )
                    }
                </select>
                <br/>
                {/*<video id="videoElement" width="600" height="400" style={ { border: "1px solid gray" } }/>*/}
                <div class="position-relative" style={{width:"100%"}} >

                    <video id="videoElement" width="100%" height="auto"></video>

                    {/*<div class="qr-shaded-region position-absolute" id="qr-shaded-region_1" style={{height: "100%", background: "rgba(0, 0, 0, 0.48)", top: "0px", left: "0px", width: "30%"}}>
                        <div class="position-absolute" style={{backgroundColor: "rgb(255, 255, 255)", width: "5px", height: 45px; top: 25%; right: 0}}></div>
                        <div class="position-absolute" style="background-color: rgb(255, 255, 255); width: 5px; height: 45px; bottom: 25%; right: 0;"></div>
                        <div class="d-none d-sm-block position-absolute" style="background-color: rgb(255, 255, 255); width: 5px; height: 40px; top: 50%; margin-top: -20px; right: 0;"></div>
                    </div>
                    <div class="qr-shaded-region position-absolute" id="qr-shaded-region_2" style="height: 100%; background: rgba(0, 0, 0, 0.48); top: 0px; right: 0px; width: 30%;">
                        <div class="position-absolute" style="background-color: rgb(255, 255, 255); width: 5px; height: 45px; top: 25%; left: 0px;"></div>
                        <div class="position-absolute" style="background-color: rgb(255, 255, 255); width: 5px; height: 45px; bottom: 25%; left: 0px;"></div>
                        <div class="d-none d-sm-block position-absolute" style="background-color: rgb(255, 255, 255); width: 5px; height: 40px; top: 50%; margin-top: -20px; left: 0;"></div>
                    </div>
                    <div class="qr-shaded-region position-absolute" id="qr-shaded-region_3" style="height: 25%; background: rgba(0, 0, 0, 0.48); top: 0px; left: 30%; width: 40%;">
                        <div class="position-absolute" style="background-color: rgb(255, 255, 255); width: 45px; height: 5px; bottom: 0px; left: -5px;"></div>
                        <div class="position-absolute" style="background-color: rgb(255, 255, 255); width: 45px; height: 5px; bottom: 0px; right: -5px;"></div>
                        <div class="d-none d-sm-block position-absolute" style="background-color: rgb(255, 255, 255); width: 45px; height: 5px; bottom: 0px; left: 50%; margin-left: -20px"></div>
                    </div>
                    <div class="qr-shaded-region position-absolute" id="qr-shaded-region_4" style="height: 25%; background: rgba(0, 0, 0, 0.48); top: 75%; left: 30%; width: 40%">
                        <div class="position-absolute" style="background-color: rgb(255, 255, 255); width: 45px; height: 5px; top: 0px; left: -5px;"></div>
                        <div class="position-absolute" style="background-color: rgb(255, 255, 255); width: 45px; height: 5px; top: 0px; right: -5px;"></div>
                        <div class="d-none d-sm-block position-absolute" style="background-color: rgb(255, 255, 255); width: 45px; height: 5px; top: 0px; left: 50%; margin-left: -20px"></div>
                </div>*/}
                </div>
            </div>

            {/*<div style ={{display: 'flex', justifyContent: 'space-between', paddingBottom: 5,paddingTop: 5 }}>
                <TextArea
                    one='true'
                    maxRows={1}
                    value={ barCode ?? 'No data scanned' }
                />
                <TextArea
                    two='true'
                    maxRows={1}
                    value={ barCodeFormat ?? 'No code' }
                />

                    <ResetOutlinedButton
                        startIcon={<ExitToAppIcon />}
                        onClick={resetAll}
                        >
                        Reset
                    </ResetOutlinedButton>
            </div>
            <div>
                <ul style={{ width:'40%' }}>
                    {/*console.log("barcodes : "+barCodes)*/}{/*
                    <div>Top {barCodes.length} reads :</div>
                    {barCodes.map((val) => (
                        <li className='rowstyle' key={val.data}>{val.data} -- {val.symbology}</li>
                    ))}
                </ul>
            </div>*/}
        </div>
    );
}

export default ZxingJSBarcodeScanner;

