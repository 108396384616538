import React, { useEffect, useState } from 'react';
import { TextareaAutosize, Paper, Button } from '@material-ui/core';
import styled from 'styled-components';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Scanner from '../Scanner';

const ResetOutlinedButton = styled(Button)`
//   border-radius: 8px;

  box-shadow: none;
  text-transform: none;
  font-size: 12px;
  align-self: center;
    //height: 47px;
  background: #fff;
  color: blue;
  &:hover {
    background: #fff;
  }

  .MuiButton-label {
    justify-content: flex-start;
  }

  &.MuiButtonBase-root.MuiButton-label.MuiButton-startIcon{
    display: inherit;
    margin-left: -4px;
    margin-right: 0px;
  }

  &.MuiButtonBase-root.Mui-disabled {
    color: darkgrey;
    border: 2px solid darkgrey !important;
  }
`;

const TextArea = styled(TextareaAutosize)`
font-size: 13px;
width: ${(props) => (props.one ? '55%' : '20%')};
margin-right: 11px;
margin-top: 3px;
margin-left: 6px;
display: inline-block;
`;

function ScanBarCode() {

  const [barCode, setBarCode] = useState(null);
  const [resetCam, setResetCam] = useState(true);
  const [format, setFormat] = useState(null);
  const [resultArr, setResultArr] = useState([]);

  const onDetected = (val, format) => {
    console.log('detected', val);
    setFormat(format);
    setBarCode(val);
    setResetCam(false);
  };

  const onDetectedArr = (arr) => {
    setResultArr(arr);
  }

  return (
    <div>
      <h2>Quagga Scanner</h2>
      <Paper variant="outlined" style={{ marginTop: 31, display: 'flex',justifyContent: 'center' }}>
        {
            resetCam && <Scanner onBarcodeDetect={onDetected}  onBarcodeDetectArr={onDetectedArr} />
        }
      </Paper>
      <div style ={{display: 'flex', justifyContent: 'space-between', paddingBottom: 5,paddingTop: 5 }}>
      <TextArea
          one='true'
          maxRows={1}
          value={ barCode ?? 'No data scanned' }
      />
      <TextArea
          two='true'
          maxRows={1}
          value={ format ?? 'No code' }
      />

        <ResetOutlinedButton
          startIcon={<ExitToAppIcon />}
          onClick={() => setResetCam(true)}
        >
          Reset
        </ResetOutlinedButton>
      </div>
      <div>
      <ul style={{ width:'40%' }}>
      <div>Top {resultArr.length} reads :</div>
      {resultArr.map((val) => (
        <li className='rowstyle' key={val.code}>{val.code} -- {val.format}</li>
      ))}
    </ul>
      </div>
    </div>
  );
}

export default ScanBarCode;
